<template>
  <div>
    <div v-if="resetPasswordData.validLink && showForm">
      <h4 class="text-center mt-3">{{ $t('pages.login.user.confirm_reset_password_title') }}</h4>
      <b-form @submit.prevent="confirmResetPassword" class="mt-3">
        <b-form-group
          id="password1-group"
          :label="$t('auth.reset_new_password')"
          label-for="password1"
          :invalid-feedback="invalidNewPassword1"
          :state="stateNewPassword1"
        >
          <b-form-input
            id="password1"
            v-model="newPassword1"
            type="password"
            :state="stateNewPassword1Input"
            :placeholder="$t('auth.placeholder_password')"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="password2-group"
          :label="$t('auth.reset_confirm_password')"
          label-for="password2"
          :invalid-feedback="invalidNewPassword2"
          :state="stateNewPassword2"
        >
          <b-form-input
            id="password2"
            v-model="newPassword2"
            type="password"
            :state="stateNewPassword2Input"
            :placeholder="$t('auth.placeholder_password')"
          ></b-form-input>
        </b-form-group>
        <div class="form-actions">
          <b-button
            type="submit"
            :disabled="loading"
            variant="primary">
            {{ $t('auth.send_password_button') }}
          </b-button>
        </div>
      </b-form>
    </div>
    <div v-else>
      <div class="mt-5 text-secondary">
        <h3 class="text-center">{{ $t('auth.reset_invalid_link') }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { USER_SECTION_PATH } from '@base/configs'
  import { required, sameAs } from 'vuelidate/lib/validators'

  export default {
    name: 'UserConfirmResetPassword',

    data () {
      return {
        newPassword1: null,
        newPassword2: null,
        showForm: true,
        loading: false
      }
    },

    created () {
      this.$store.dispatch(this.$_actionTypes.GET_RESET_PASSWORD_DATA, {
        marketId: this.anonMarket.marketId ,...this.$route.query
      })
        .then(() => {
          if (!this.resetPasswordData.validLink) {
            this.$router.push({name: 'user-login'})
          }
        })
    },

    validations: {
      newPassword1: {
        required,
      },
      newPassword2: {
        required,
        sameAs: sameAs('newPassword1')
      }
    },

    computed: {
      ...mapState(['resetPasswordData', 'anonMarket']),

      payloads () {
        return {
          marketId: this.anonMarket.marketId,
          newPassword1: this.newPassword1,
          newPassword2: this.newPassword2,
          uid: this.resetPasswordData.uid,
          token: this.resetPasswordData.token,
        }
      },
      stateNewPassword1Input () {
        return (this.$v.newPassword1.$error || !this.$v.newPassword2.sameAs) ? false : null
      },
      stateNewPassword1 () {
        return (!this.$v.newPassword1.$error)
      },
      invalidNewPassword1 () {
        if (this.$v.newPassword1.$error) {
          if (!this.$v.newPassword1.required)
            return this.$t('validation.required', { field: this.$t('auth.field.password') })
        }
        return null
      },
      stateNewPassword2Input () {
        return (this.$v.newPassword2.$error || !this.$v.newPassword2.sameAs) ? false : null
      },
      stateNewPassword2 () {
        return (!this.$v.newPassword2.$error)
      },
      invalidNewPassword2 () {
        if (this.$v.newPassword2.$error) {
          if (!this.$v.newPassword2.required)
            return this.$t('validation.required', { field: this.$t('auth.field.password') })
          else if (!this.$v.newPassword2.sameAs)
            return this.$t('validation.same_as', { field: this.$t('auth.field.password') })
        }
        return null
      },
    },

    methods: {

      confirmResetPassword () {
        this.$v.$touch()
        if (this.$v.$invalid)
          return
        this.loading = true
        this.loader = this.$loading.show(this.$_loaderOptions)
        this.$store.dispatch(this.$_actionTypes.CONFIRM_RESET_PASSWORD, this.payloads)
          .then((response) => {
            this.$notify({
              group: 'app',
              type: 'success',
              title: this.$t('notifications.title.success'),
              text: response.detail
            })
            const loginPayloads = {
              username: this.resetPasswordData.email,
              password: this.newPassword1,
              market_id: this.anonMarket.marketId,
            }
            this.$store.dispatch(this.$_actionTypes.LOGIN_USER, loginPayloads)
              .then(() => {
                window.location.replace(USER_SECTION_PATH)
              })
              .catch(error => {
                this.$_notifyError(error, this)
              })
          })
          .catch((error) => {
            this.$_notifyError(error, this)
          })
          .finally(() => {
            this.loading = false
            this.loader.hide()
          })
      }
    }
  }
</script>

<style>

</style>
