import axios from 'axios'
import { marketConfig, userConfig, webConfig } from '@base/configs'

export const authUser = axios.create({
  method: 'post',
  baseURL: `${userConfig.baseURL}/token/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const resetPassword = axios.create({
  method: 'post',
  baseURL: `${webConfig.baseURL}/password/reset/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const resetPasswordConfirm = axios.create({
  method: 'post',
  baseURL: `${webConfig.baseURL}/password/reset/confirm/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const emailConfirm = axios.create({
  method: 'post',
  baseURL: `${webConfig.baseURL}/email/confirm/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const getResetPasswordData = axios.create({
  method: 'post',
  baseURL: `${userConfig.baseURL}/get_reset_password_data/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const getEmailConfirmationData = axios.create({
  method: 'post',
  baseURL: `${userConfig.baseURL}/get_email_confirmation_data/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const sendFeedback = axios.create({
  method: 'post',
  baseURL: `${userConfig.baseURL}/send_feedback/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const createUser = axios.create({
  method: 'post',
  baseURL: `${userConfig.baseURL}/register_user/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

// Market

export const createMarket = axios.create({
  method: 'post',
  baseURL: `${userConfig.baseURL}/register_market/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const getAnonMarket = axios.create({
  method: 'get',
  baseURL: `${marketConfig.baseURL}/market/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})
