<template>
  <div>
    <b-carousel
      id="carousel-1"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      style="text-shadow: 1px 1px 2px #333;"
    >

      <b-carousel-slide>
        <template v-slot:img>
          <b-img
            v-bind="mainProps"
            width="1024"
            height="480"
            class="d-block w-100"
            :src="require('@base/assets/images/loppeonline_store_image.png')"
            alt="loppeonline" />
        </template>
      </b-carousel-slide>

    </b-carousel>

    <b-container>
      <b-row class="d-flex justify-content-center mt-5">
        <b-card-group deck>
          <b-card
            class="d-flex flex-direction-column"
            :title="$t('pages.home.title.card1')"
            img-blank
            align="center"
            img-top
            style="max-width: 20rem"
            tag="article"
          >
            <b-card-text>
              {{ $t('pages.home.text.card1') }}
            </b-card-text>

            <template v-slot:footer>
              <b-button
                block
                class="align-bottom"
                href="https://loppeonline.dk/butikker/"
                variant="primary">
                {{ $t('pages.home.card1.button') }}
              </b-button>
            </template>
          </b-card>
          <b-card
            :title="$t('pages.home.title.card2')"
            img-blank
            align="center"
            img-top
            style="max-width: 20rem"
            tag="article"
          >
            <b-card-text>
              {{ $t('pages.home.text.card2', { appName }) }}
            </b-card-text>

            <template v-slot:footer>
              <b-button
                block
                :to="{ name: 'admin-login' }"
                variant="primary">
                {{ $t('pages.home.card2.button') }}
              </b-button>
            </template>
          </b-card>
          <b-card
            :title="$t('pages.home.title.card3', { appName })"
            img-blank
            align="center"
            img-top
            style="max-width: 20rem"
            tag="article"
          >
            <b-card-text>
              {{ $t('pages.home.text.card3', { appDomain }) }}
            </b-card-text>

            <template v-slot:footer>
              <b-button
                block
                class="align-bottom"
                @click="$bvModal.show('feedback-form')"
                variant="primary">
                {{ $t('pages.home.card3.button', { appName }) }}
              </b-button>
            </template>
          </b-card>
        </b-card-group>
      </b-row>
      <feedback-modal
        modalId="feedback-form"
        @formSubmit="sendFeedback"
      />
    </b-container>
  </div>
</template>

<script>

  import FeedbackModal from '@base/components/FeedbackModal.vue'
  import { APP_DOMAIN, APP_NAME } from "@base/configs";

  export default {
    name: 'Home',

    components: {
      FeedbackModal
    },

    data () {
      return {
        mainProps: {
          center: true,
          fluidGrow: true,
          width: 600,
          height: 600,
        },
        appDomain: APP_DOMAIN,
        appName: APP_NAME,
      }
    },

    created () {
      this.$store.commit(this.$_mutationTypes.SET_LOADER, false)
    },

    methods: {
      sendFeedback (payloads) {
        this.loader = this.$loading.show(this.$_loaderOptions)
        this.$store.dispatch(this.$_actionTypes.SEND_FEEDBACK, payloads)
          .then(() => {
            this.$nextTick(() => {
              this.$bvModal.hide('feedback-form')
            })
            this.$notify({
              group: 'app',
              type: 'success',
              title: this.$t('notifications.title.success'),
              text: this.$t('notifications.text.feedback.success_feedback_sent')
            })
          })
          .catch((error) => {
            this.$_notifyError(error, this)
          })
          .finally(() => {
            this.loader.hide()
          })
      }
    },
  }
</script>

<style>

</style>
