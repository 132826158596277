import Vue from 'vue'
import App from '@base/App.vue'

// Font Awesome Icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// local modules
import i18n from '@base/i18n'
import store from '@anonymous/store'
import router from '@anonymous/router'

// library modules
import Loading from 'vue-loading-overlay'
import vueMoment from 'vue-moment'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Notifications from 'vue-notification'
import Vuelidate from 'vuelidate'
import moment from 'moment'
import 'moment/locale/da'
import 'moment/locale/de'
import 'moment/locale/en-gb'
import Multiselect from 'vue-multiselect'
import VuePhoneNumberInput from 'vue-phone-number-input'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'

// CSS
import 'vue-loading-overlay/dist/vue-loading.css'
import '@base/styles/ourStyles.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

library.add(faInstagram)
library.add(faFacebookF)

Vue.use(Notifications)
Vue.use(Loading)
Vue.use(Vuelidate)
Vue.use(vueMoment, { moment })
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueVideoPlayer)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('multiselect', Multiselect)
Vue.component('vue-phone-number-input', VuePhoneNumberInput)
Vue.component('vue-google-autocomplete', VueGoogleAutocomplete)

Vue.config.devtools = process.env.VUE_APP_DEVTOOLS || false

new Vue({
  i18n,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
