<template>
  <div>
    <b-container>
      <b-row class="justify-content-center mt-5"><h1>{{ $t('pages.create_market.title') }}</h1></b-row>
      <b-row class="justify-content-center"><h6>{{ $t('pages.create_market.subtitle') }}</h6></b-row>
      <b-row class="justify-content-center">
        <b-form @submit.prevent="addUser" class="w-50">

          <!--USERNAME-->
          <b-form-group
            id="username-group"
            :label="$t('form.market.user.username_label')"
            label-for="username"
            :invalid-feedback="invalidUsername"
            :state="stateUsername"
          >
            <b-form-input
              id="username"
              v-model="userForm.username"
              type="text"
              :state="stateUsernameInput"
              :placeholder="$t('form.market.user.username_field')"
            ></b-form-input>
          </b-form-group>
          
          <!--EMAIL-->
          <b-form-group
            id="email-group"
            :label="$t('form.market.user.email')"
            :invalid-feedback="invalidEmail"
            :state="stateEmail"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="userForm.email"
              :state="stateEmailInput"
              :placeholder="$t('form.market.user.email')"
            ></b-form-input>
          </b-form-group>

          <!--PASSWORD-->
          <b-form-group
            id="password-group"
            :label="$t('form.market.user.password_label')"
            label-for="password"
            :invalid-feedback="invalidPassword"
            :state="statePassword"
          >
            <b-form-input
              id="password"
              v-model="userForm.password"
              type="password"
              :state="statePasswordInput"
              :placeholder="$t('form.market.user.password_field')"
            ></b-form-input>
          </b-form-group>

          <!--ADMIN PASSWORD-->
          <b-form-group
            id="admin-password-group"
            :label="$t('form.market.user.admin_password')"
            label-for="admin-password"
            :invalid-feedback="invalidAdminPassword"
            :state="stateAdminPassword"
          >
            <b-form-input
              id="admin-password"
              v-model="userForm.adminPassword"
              type="password"
              :state="stateAdminPasswordInput"
              :placeholder="$t('form.market.user.password_field')"
            ></b-form-input>
          </b-form-group>
          <vue-recaptcha
            v-if="useRecaptcha"
            ref="recaptcha"
            @expired="onCaptchaExpired"
            @verify="onCaptchaVerified"
            @error="onRecaptchaError"
            :sitekey="recaptchaSiteKey"
            size="invisible" />
          <b-button
            class="offset-md-5"
            :disabled="loading"
            type="submit"
            variant="primary">
            {{ $t('form.market.user.button_create_user') }}
          </b-button>
        </b-form>
      </b-row>
    </b-container>

  </div>
</template>

<script>
  import VueRecaptcha from 'vue-recaptcha'
  import { RECAPTCHA_SITE_KEY, ADMIN_SECTION_PATH, USE_RECAPTCHA } from '@base/configs'
  import { required, email } from 'vuelidate/lib/validators'

  export const defaultUserForm = {
    username: '',
    email: '',
    password: '',
    adminPassword: ''
  }

  export default {
    name: 'CreateUser',

    components: { VueRecaptcha },

    data () {
      return {
        userForm: { ...defaultUserForm },
        useRecaptcha: USE_RECAPTCHA,
        recaptchaToken: null,
        recaptchaSiteKey: RECAPTCHA_SITE_KEY,
        recaptchaError: false,
        loading: false
      }
    },

    computed: {
      payloads () {
        return {
          username: this.userForm.username,
          email: this.userForm.email,
          password: this.userForm.password,
          adminPassword: this.userForm.adminPassword,
        }
      },

      // VALIDATIONS

      stateUsernameInput () {
        return this.$v.userForm.username.$error ? false : null
      },
      stateUsername () {
        return (!this.$v.userForm.username.$error)
      },
      invalidUsername () {
        if (this.$v.userForm.username.$error) {
          if (!this.$v.userForm.username.required)
            return this.$t('validation.required', { field: this.$t('form.market.user.username_field') })
        }
        return null
      },
      statePasswordInput () {
        return this.$v.userForm.password.$error ? false : null
      },
      statePassword () {
        return (!this.$v.userForm.password.$error)
      },
      invalidPassword () {
        if (this.$v.userForm.password.$error) {
          if (!this.$v.userForm.password.required)
            return this.$t('validation.required', { field: this.$t('form.market.user.password_field') })
        }
        return null
      },
      stateEmailInput () {
        return this.$v.userForm.email.$error ? false : null
      },
      stateEmail () {
        return (!this.$v.userForm.email.$error)
      },
      invalidEmail () {
        if (this.$v.userForm.email.$error) {
          if (!this.$v.userForm.email.required)
            return this.$t('validation.required', { field: this.$t('form.market.user.email') })
          else if (!this.$v.userForm.email.email)
            return this.$t('validation.email')
        }
        return null
      },
      stateAdminPasswordInput () {
        return this.$v.userForm.adminPassword.$error ? false : null
      },
      stateAdminPassword () {
        return (!this.$v.userForm.adminPassword.$error)
      },
      invalidAdminPassword () {
        if (this.$v.userForm.adminPassword.$error) {
          if (!this.$v.userForm.adminPassword.required)
            return this.$t('validation.required', { field: this.$t('form.market.user.admin_password') })
        }
        return null
      },
    },

    validations: {
      userForm: {
        username: {
          required,
        },
        email: {
          email,
          required
        },
        password: {
          required,
        },
        adminPassword: {
          required
        }
      }
    },

    methods: {
      onRecaptchaError () {
        this.recaptchaError = true
      },

      onCaptchaExpired () {
        if (this.useRecaptcha)
          this.$refs.recaptcha.reset()
      },

      addUser () {
        this.$v.$touch()
        if (this.useRecaptcha) {
          if (this.recaptchaError) {
            this.$notify({
              group: 'app',
              type: 'error',
              title: this.$t('notifications.title.error'),
              text: this.$t('error.recaptcha')
            })
            return
          }
        }
        if (!this.$v.$invalid) {
          if (this.useRecaptcha)
            this.$refs.recaptcha.execute()
          else
            this.onCaptchaVerified()
        }
      },

      onCaptchaVerified (recaptchaToken) {
        this.$v.$reset()
        if (this.useRecaptcha) {
          this.recaptchaToken = recaptchaToken
          this.$refs.recaptcha.reset()
        }
        this.loading = true
        this.loader = this.$loading.show(this.$_loaderOptions)
        this.$store.dispatch(this.$_actionTypes.CREATE_USER, this.payloads)
          .then((response) => {
            if (response.username && response.password) {
              const loginPayloads = {
                username: this.payloads.username,
                password: this.payloads.password,
                grecaptcha_token: this.useRecaptcha ? this.recaptchaToken: undefined
              }
              this.$store.dispatch(this.$_actionTypes.LOGIN_USER, loginPayloads)
                .then(() => {
                  window.location.replace(ADMIN_SECTION_PATH)
                })
                .catch(error => {
                  this.$_notifyError(error, this)
                })
            }
            else {
              this.$notify({
                group: 'app',
                type: 'error',
                title: this.$t('notifications.title.error'),
                text: this.$t('error.internal_error')
              })
            }
          })
          .catch((error) => {
            this.$_notifyError(error, this)
          })
          .finally(() => {
            this.loading = false
            this.loader.hide()
          })
      }
    }
  }
</script>

<style>

</style>
