<template>
  <div class="form-box-wrap">
    <b-container>
      <div v-if="isGlobalApp" class="d-flex justify-content-center mt-2 mb-2">
        <b-img width="150" height="130" :src="require('@base/assets/images/flealover-sitelogo.png')" />
      </div>
      <div v-else class="d-flex justify-content-center">
        <b-img :src="require('@base/assets/images/sitelogo.png')" />
      </div>
      <div
        class="mt-1"
        v-if="emailConfirmationData.validLink && !emailConfirmationData.emailVerified"
      >
        <h3 class="text-center">{{ $t('pages.email_confirm.title') }}</h3>
        <p
          class="text-center"
          v-html="$t('pages.email_confirm.confirmation_text', { email: emailConfirmationData.email })">
        </p>
        <b-row class="justify-content-center">
          <b-form @submit.prevent="verifyEmail" class="w-50">
            <b-form-group
              id="password1-group"
              :label="$t('pages.email_confirm.password1')"
              label-for="password1"
              :invalid-feedback="invalidNewPassword1"
              :state="stateNewPassword1"
            >
              <b-form-input
                id="password1"
                v-model="newPassword1"
                type="password"
                :state="stateNewPassword1Input"
                :placeholder="$t('auth.placeholder_password')"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="password2-group"
              :label="$t('pages.email_confirm.password2')"
              label-for="password2"
              :invalid-feedback="invalidNewPassword2"
              :state="stateNewPassword2"
            >
              <b-form-input
                id="password2"
                v-model="newPassword2"
                type="password"
                :state="stateNewPassword2Input"
                :placeholder="$t('auth.placeholder_password')"
              ></b-form-input>
            </b-form-group>
            <vue-recaptcha
              v-if="useRecaptcha"
              ref="recaptcha"
              @expired="onCaptchaExpired"
              @verify="onCaptchaVerified"
              @error="onRecaptchaError"
              :sitekey="recaptchaSiteKey"
              size="invisible" />
            <div class="form-actions">
              <b-button
                :disabled="loading"
                type="submit"
                variant="primary">
                {{ $t('pages.email_confirm.button_verify') }}
              </b-button>
            </div>
          </b-form>
        </b-row>
      </div>
      <div class="text-secondary mt-5" v-else-if="emailConfirmationData.validLink && emailConfirmationData.emailVerified">
        <h3 class="text-center">
          {{ $t('pages.email_confirm.already_confirmed', {
            username: emailConfirmationData.user,
            email: emailConfirmationData.email
          }) }}
        </h3>
      </div>
      <div v-else class="text-secondary mt-5">
        <h3 class="text-center">
          {{ $t('pages.email_confirm.invalid_link') }}
        </h3>
      </div>
    </b-container>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import VueRecaptcha from 'vue-recaptcha'
  import {
    ADMIN_SECTION_PATH,
    IS_GLOBAL_APP,
    RECAPTCHA_SITE_KEY,
    USE_RECAPTCHA
  } from '@base/configs'
  import { required, sameAs } from 'vuelidate/lib/validators'

  export default {
    name: 'AdminEmailConfirm',

    components: { VueRecaptcha },

    data () {
      return {
        newPassword1: null,
        newPassword2: null,
        useRecaptcha: USE_RECAPTCHA,
        recaptchaToken: null,
        recaptchaSiteKey: RECAPTCHA_SITE_KEY,
        recaptchaError: false,
        loading: false,
        isGlobalApp: IS_GLOBAL_APP,
      }
    },

    created () {
      this.$store.dispatch(this.$_actionTypes.GET_CONFIRMATION_DATA, this.$route.query)
        .then(() => {
          if (this.emailConfirmationData.emailVerified || !this.emailConfirmationData.validLink) {
            this.$router.push({name: 'admin-login'})
          }
        })
    },

    validations: {
      newPassword1: {
        required,
      },
      newPassword2: {
        required,
        sameAs: sameAs('newPassword1')
      }
    },

    computed: {
      ...mapState(['emailConfirmationData']),

      payloads () {
        return {
          newPassword1: this.newPassword1,
          newPassword2: this.newPassword2,
          uid: this.emailConfirmationData.uid,
          token: this.emailConfirmationData.token,
        }
      },

      // VALIDATIONS
      stateNewPassword1Input () {
        return (this.$v.newPassword1.$error || !this.$v.newPassword2.sameAs) ? false : null
      },
      stateNewPassword1 () {
        return (!this.$v.newPassword1.$error)
      },
      invalidNewPassword1 () {
        if (this.$v.newPassword1.$error) {
          if (!this.$v.newPassword1.required)
            return this.$t('validation.required', { field: this.$t('auth.field.password') })
        }
        return null
      },
      stateNewPassword2Input () {
        return (this.$v.newPassword2.$error || !this.$v.newPassword2.sameAs) ? false : null
      },
      stateNewPassword2 () {
        return (!this.$v.newPassword2.$error)
      },
      invalidNewPassword2 () {
        if (this.$v.newPassword2.$error) {
          if (!this.$v.newPassword2.required)
            return this.$t('validation.required', { field: this.$t('auth.field.password') })
          else if (!this.$v.newPassword2.sameAs)
            return this.$t('validation.same_as', { field: this.$t('auth.field.password') })
        }
        return null
      },
    },

    methods: {

      onRecaptchaError () {
        this.recaptchaError = true
      },

      onCaptchaExpired () {
        if (this.useRecaptcha)
          this.$refs.recaptcha.reset()
      },

      verifyEmail () {
        this.$v.$touch()
        if (this.useRecaptcha) {
          if (this.recaptchaError) {
            this.$notify({
              group: 'app',
              type: 'error',
              title: this.$t('notifications.title.error'),
              text: this.$t('error.recaptcha')
            })
            return
          }
        }
        if (!this.$v.$invalid) {
          if (this.useRecaptcha)
            this.$refs.recaptcha.execute()
          else
            this.onCaptchaVerified()
        }
      },

      onCaptchaVerified (recaptchaToken) {
        this.$v.$reset()
        if (this.useRecaptcha) {
          this.recaptchaToken = recaptchaToken
          this.$refs.recaptcha.reset()
        }
        this.loading = true
        this.loader = this.$loading.show(this.$_loaderOptions)
        this.$store.dispatch(this.$_actionTypes.CONFIRM_EMAIL, this.payloads)
          .then((response) => {
            this.$notify({
              group: 'app',
              type: 'success',
              title: this.$t('notifications.title.success'),
              text: response.detail
            })
            const loginPayloads = {
              username: this.emailConfirmationData.email,
              password: this.newPassword1,
              grecaptcha_token: this.useRecaptcha ? this.recaptchaToken: undefined,
            }
            this.$store.dispatch(this.$_actionTypes.LOGIN_USER, loginPayloads)
              .then(() => {
                window.location.replace(ADMIN_SECTION_PATH)
              })
              .catch(error => {
                this.$_notifyError(error, this)
              })
          })
          .catch((error) => {
            this.$_notifyError(error, this)
          })
          .finally(() => {
            this.loading = false
            this.loader.hide()
          })
      },
    }
  }
</script>

<style>

</style>
