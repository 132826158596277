<template>
  <b-card
    class="mt-2 no-margin-left market-description-card"
    img-blank
    align="center"
    img-top
    tag="article"
  >
    <template v-slot:header>
      <h4
        class="mb-0"
      >
        {{ market.visualName }}
      </h4>
    </template>
    <b-card-text class="pre-wrap text-left">
      {{ market.description }}
    </b-card-text>

    <template v-slot:footer>
      <span
        v-if="market.extraMarketInfo"
        class="custom-card-footer pre-wrap"
      >
        {{ market.extraMarketInfo }}
      </span>
    </template>
  </b-card>
</template>

<script>

  export default {
    name: 'MarketDescriptionCard',

    data () {
      return {
      }
    },

    props: {
      market: {
        type: Object,
        required: true,
      }
    }
  }
</script>

<style>

</style>
