<template>
  <div>
    <b-container>
      <div v-if="isGlobalApp" class="d-flex justify-content-center mt-2 mb-2">
        <b-img width="150" height="130" :src="require('@base/assets/images/flealover-sitelogo.png')" />
      </div>
      <div v-else class="d-flex justify-content-center">
        <b-img :src="require('@base/assets/images/sitelogo.png')" />
      </div>
      <b-row class="justify-content-center"><h2>{{ $t('title.auth.reset_password') }}</h2></b-row>
      <b-row class="justify-content-center">
        <div v-if="showForm">
          <b-form @submit.prevent="resetPassword">
            <b-form-group
              id="email-group"
              class="text-center"
              :label="$t('auth.reset_password_email_field', { appName })"
              :invalid-feedback="invalidEmail"
              :state="stateEmail"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="email"
                type="email"
                :state="stateEmailInput"
                :placeholder="$t('auth.placeholder_email')"
              ></b-form-input>
            </b-form-group>
            <div class="form-actions">
              <b-button
                type="submit"
                :disabled="loading"
                variant="primary">
                {{ $t('auth.send_password_button') }}
              </b-button>
            </div>
          </b-form>
          <b-row class="justify-content-center mt-3">
            <p>
              <router-link :to="{name: 'admin-login'}">
                {{ $t('auth.back_to_login') }}
              </router-link>
            </p>
          </b-row>
        </div>
        <div v-else>
          <h3>
            {{ $t('notifications.text.auth.success_reset_password') }}
          </h3>
          <b-button
            class="offset-3"
            type="submit"
            @click="showForm = true"
            variant="primary">
            {{ $t('auth.reset_another') }}
          </b-button>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import { required, email } from 'vuelidate/lib/validators'
  import { APP_NAME, IS_GLOBAL_APP } from "@base/configs";

  export default {
    name: 'AdminResetPassword',

    data () {
      return {
        email: null,
        showForm: true,
        loading: false,
        isGlobalApp: IS_GLOBAL_APP,
        appName: APP_NAME,
      }
    },

    computed: {
      payloads () {
        return {
          email: this.email
        }
      },
      stateEmailInput () {
        return this.$v.email.$error ? false : null
      },
      stateEmail () {
        return (!this.$v.email.$error)
      },
      invalidEmail () {
        if (this.$v.email.$error) {
          if (!this.$v.email.required)
            return this.$t('validation.required', { field: this.$t('auth.field.email') })
          else if (!this.$v.email.email)
            return this.$t('validation.email')
        }
        return null
      },
    },

    validations: {
      email: {
        required,
        email
      }
    },

    methods: {
      resetPassword () {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.$v.$reset()
          this.loading = true
          this.loader = this.$loading.show(this.$_loaderOptions)
          this.$store.dispatch(this.$_actionTypes.RESET_PASSWORD, this.payloads)
            .then((response) => {
              this.$notify({
                group: 'app',
                type: 'success',
                title: this.$t('notifications.title.success'),
                text: response.detail
              })
              this.email = null
              this.showForm = false
            })
            .catch((error) => {
              this.$_notifyError(error, this)
            })
            .finally(() => {
              this.loading = false
              this.loader.hide()
            })
        }
      }
    }
  }
</script>

<style>

</style>
