<template>
  <div>
    <h4 class="text-center mt-3">{{ $t('pages.login.user.login_title') }}</h4>
    <b-form @submit.prevent="authUser">
      <b-form-group
        id="username-group"
        :label="$t('auth.email')"
        label-for="username"
        :invalid-feedback="invalidCredentials"
        :state="stateUsername"
      >
        <b-form-input
          id="username"
          v-model="username"
          type="text"
          :state="stateUsernameInput"
          :placeholder="$t('auth.placeholder_login')"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="password-group"
        :label="$t('auth.password')"
        label-for="password"
        :invalid-feedback="invalidPassword"
        :state="statePassword"
      >
        <b-form-input
          id="password"
          v-model="password"
          type="password"
          @blur="invalidCredentials = null"
          :state="statePasswordInput"
          :placeholder="$t('auth.placeholder_password')"
        ></b-form-input>
      </b-form-group>
      <div class="form-actions">
        <b-button
          type="submit"
          :disabled="loading"
          variant="primary">
          {{ $t('auth.login_button') }}
        </b-button>
      </div>
      <p class="text-center mt-2">
        {{ $t('auth.forgot_password') }}
        <router-link :to="{name: 'user-reset-password'}">
          {{ $t('auth.reset_password') }}
        </router-link>
      </p>
    </b-form>
  </div>
</template>

<script>
  import { ADMIN_SECTION_PATH, USER_SECTION_PATH } from '@base/configs'
  import { required } from 'vuelidate/lib/validators'
  import { mapState } from 'vuex'

  export default {
    name: 'UserLogin',

    data () {
      return {
        username: null,
        password: null,
        shopId: null,
        invalidCredentials: null,
        loading: false
      }
    },

    watch: {
      username () {
        this.invalidCredentials = null
      },
      password () {
        this.invalidCredentials = null
      },
      shopId () {
        this.invalidCredentials = null
      }
    },

    computed: {
      ...mapState(['anonMarket']),

      payloads () {
        return {
          username: this.username,
          password: this.password,
          market_id: this.$route.params.marketId
        }
      },
      stateUsernameInput () {
        return this.invalidCredentials !== null ? false : null
      },
      stateUsername () {
        return this.invalidCredentials === null
      },
      statePasswordInput () {
        return (this.$v.password.$error || this.invalidCredentials !== null) ? false : null
      },
      statePassword () {
        return !this.$v.password.$error || this.invalidCredentials !== null
      },
      invalidPassword () {
        if (this.$v.password.$error) {
          if (!this.$v.password.required)
            return this.$t('validation.required', { field: this.$t('auth.field.password') })
        }
        return null
      },
    },

    validations: {
      username: {
        required,
      },
      password: {
        required
      },
    },

    methods: {
      authUser () {
        this.$v.$touch()
        if (this.$v.$invalid)
          return
        this.invalidCredentials = null
        this.loading = true
        this.loader = this.$loading.show(this.$_loaderOptions)
        this.$store.dispatch(this.$_actionTypes.LOGIN_USER, this.payloads)
          .then((response) => {
            if (response.isAdmin) {
              window.location.replace(ADMIN_SECTION_PATH)
            } else {
              window.location.replace(USER_SECTION_PATH)
            }
          })
          .catch((error) => {
            this.invalidCredentials = error.data.error
            this.$notify({
              group: 'app',
              type: 'error',
              title: this.$t('notifications.title.error'),
              text: error.data.error
            })
          })
          .finally(() => {
            this.loading = false
            this.loader.hide()
          })
      }
    }
  }
</script>

<style>

</style>
