<template>
  <div></div>
</template>

<script>
  export default {
    methods: {
      countDownTimer(countDown, context, path) {
        this.countDown = countDown
        if (this.countDown > 0) {
          setTimeout(() => {
            this.countDown -= 1
            this.countDownTimer(this.countDown, context, path)
          }, 1000)
        } else {
          context.$router.push(path)
        }
      }
    }
  }
</script>

<style scoped>

</style>
